import React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import reportIcon from "../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Report.svg";
import SocialIconBlock from "../../../components/socialIconBlock";
import bannerImg from "../../../images/why-wakix/hero-about.webp";
import bannerImgMobile from "../../../images/why-wakix/hero-about-mobile.webp";
// import useDeviceSize from "../../../hooks/use-device-size";
import { StaticImage } from "gatsby-plugin-image";
import InnerBanner from "../../../components/InnerBanner";
import "./wakix-clinical-trials.scss";

const WakixClinicalTrials = () => {
    // const deviceSizes = useDeviceSize();

    return (
        <HcpLayout pageClass="hcp-main wakix-clinical-trials">
            <InnerBanner
                topHeading="WAKIX Clinical Trials"
                bannerHeroImg={bannerImg}
                bannerHeroImgMobile={bannerImgMobile}
                bannerImgAlt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with friends"
                heroNameImg={
                    <StaticImage
                        src="../../../images/why-wakix/hero-about-name.webp"
                        alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                        width={347}
                        height={138}
                        quality={95}
                        layout="fixed"
                        placeholder="none"
                        style={{ transition: "opacity 0.5s" }}
                        imgStyle={{ opacity: 1 }}
                    />
                }
            />

            <section className="block-clinical-trials">
                <div className="centered-content">
                    <div className="block-clinical-trials__title">
                        <h1 className="font-40">WAKIX Clinical Trials</h1>
                    </div>
                    <div className="block-clinical-trials__content">
                        <div className="block-iconWrap">
                            <div className="block-iconWrap__icon">
                                <img
                                    src={reportIcon}
                                    alt="WAKIX clinical trials icon"
                                />
                            </div>
                            <div className="block-iconWrap__content">
                                <p>
                                    WAKIX has been studied in 3 clinical trials
                                    in adults with narcolepsy and 1 clinical
                                    trial in children 6-17 years old with
                                    narcolepsy.
                                </p>
                                <p>
                                    In the clinical trials in adults with
                                    narcolepsy, WAKIX significantly reduced
                                    excessive daytime sleepiness (EDS) and the
                                    number of cataplexy attacks compared with
                                    placebo (sugar pill). In the clinical trial
                                    of children 6-17 years old with narcolepsy,
                                    WAKIX significantly reduced EDS compared
                                    with placebo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <Link
                            to="/about-wakix/wakix-clinical-trials/adult-clinical-trial-results"
                            className="btn btn-primary"
                        >
                            See results in adults
                            <TfiAngleRight />
                        </Link>

                        <Link
                            to="/about-wakix/wakix-clinical-trials/pediatric-clinical-trial-results"
                            className="btn btn-primary btn-primaryAlt"
                        >
                            <span className="text">
                                See results in children{" "}
                                <nobr>6-17 years old</nobr>
                            </span>

                            <span className="icon">
                                <TfiAngleRight />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            <SocialIconBlock />
        </HcpLayout>
    );
};

export default WakixClinicalTrials;

export const Head = () => (
    <Seo
        title="Clinical Trial Results | WAKIX® (pitolisant)"
        description="WAKIX has been studied in 3 clinical trials in adults with narcolepsy and 1 clinical trial in children 6 years & older with narcolepsy."
        keywords="WAKIX trials, pitolisant trials, WAKIX FDA approval"
    />
);
