import React, { useEffect, useRef } from "react";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import axios from "axios";
import "./sign-up.scss";
import bannerImg from "../images/wakix-for-you/hero-wakix-for-you.webp";
import bannerImgMobile from "../images/wakix-for-you/hero-wakix-for-you-mobile.webp";
import heroName from "../images/wakix-for-you/hero-wakix-for-you-name.webp";
import useDeviceSize from "../hooks/use-device-size";
import { Col, Row } from "react-grid-system";
import { Link } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import { BsChevronRight } from "react-icons/bs";

const ELQ_FORM = "Wakix_Patient_Support_CTP_Update";
const ELQ_SITE_ID = "1241076867";

const SignUpPage = () => {
    const deviceSizes = useDeviceSize();
    const [currStep, setCurrStep] = React.useState(1);
    const [formData, setFormData] = React.useState({});
    const formRef1 = useRef(null);
    const formRef2 = useRef(null);
    const formRef3 = useRef(null);

    useEffect(() => {
        if (currStep === 1 && formRef1.current) {
            formRef1.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } else if (currStep === 2.1 && formRef2.current) {
            formRef2.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } else if ((currStep === 2.2 || currStep === 2.3) && formRef3.current) {
            formRef3.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [currStep]);

    const {
        handleSubmit: handleSubmit1,
        control: control1,
        formState: { errors: errors1 },
    } = useForm({
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit2a,
        control: control2a,
        formState: { errors: errors2a },
    } = useForm({
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit2b,
        control: control2b,
        formState: { errors: errors2b },
    } = useForm({
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit2c,
        control: control2c,
        formState: { errors: errors2c },
    } = useForm({
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit3,
        register: register3,
        watch: watch3,
        control,
        formState: { errors: errors3 },
    } = useForm({
        mode: "onBlur",
    });

    const handleStepNext = (d) => {
        if (currStep === 1) {
            if (d.segment === "Suport_Network") {
                delete formData.prescribed_wakix;
                delete formData.child_prescribed;
                setFormData(formData);
                setCurrStep(2.2);
            } else if (d.segment === "Parent_Caregiver") {
                delete formData.loved_one_prescribed;
                delete formData.prescribed_wakix;
                setFormData(formData);
                setCurrStep(2.3);
            } else {
                delete formData.loved_one_prescribed;
                delete formData.child_prescribed;
                setFormData(formData);
                setCurrStep(2.1);
            }
        } else {
            setCurrStep(3);
        }
        setFormData({ ...formData, ...d });
    };
    const handleStepBack = () => {
        if (currStep === 3) {
            if (formData.segment === "Suport_Network") {
                setCurrStep(2.2);
            } else if (formData.segment === "Parent_Caregiver") {
                setCurrStep(2.3);
            } else {
                setCurrStep(2.1);
            }
        } else {
            setCurrStep(1);
        }
    };

    const handleFormSubmit = async (d) => {
        setFormData({ ...formData, ...d });
        await sendPostRequest({ ...formData, ...d });
    };

    const sendPostRequest = async (obj) => {
        // console.log("formData", obj);
        const {
            email_address,
            confirmEmail,
            first_name,
            last_name,
            address_line_1,
            address_line_2,
            city,
            state,
            zip_code,
            phone,
            over_18,
            segment,
            prescribed_wakix,
            // started_wakix,
            loved_one_prescribed,
            child_prescribed,
        } = obj;
        const dataToSend = {
            elqFormName: ELQ_FORM,
            elqSiteID: ELQ_SITE_ID,
            email_address: email_address,
            confirm_email_address: confirmEmail,
            first_name: first_name,
            last_name: last_name,
            address_line_1: address_line_1,
            address_line_2: address_line_2,
            city: city,
            state: state,
            zip_code: zip_code,
            phone: phone,
            over_18: over_18,
            segment: segment,
            prescribed_wakix: prescribed_wakix,
            // started_wakix: started_wakix,
            loved_one_prescribed: loved_one_prescribed,
            child_prescribed: child_prescribed,
        };

        try {
            const response = await axios.post("/crm/signup", dataToSend, {});
            // console.log("response");
            setCurrStep(5);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    function validateNumber(e) {
        if (
            !(
                (e.keyCode > 95 && e.keyCode < 106) ||
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode === 8
            )
        ) {
            e.preventDefault();
        }
    }

    return (
        <HcpLayout pageClass="sign-up__page" noStickyHCPLocatorCTA={true}>
            <div className="top-heading">
                <div className="centered-content">
                    <h2>Learn More</h2>
                </div>
            </div>
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                alt="Kristen, a real person with narcolepsy taking WAKIX, talking on her phone"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                alt="Kristen, a real person with narcolepsy taking WAKIX, talking on her phone"
                            />
                        )}
                        <div className="centered-content">
                            <div className="hero-name">
                                <img
                                    src={heroName}
                                    alt="Kristen - Living with narcolepsy Taking WAKIX since 2020"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="page-info-text">
                <div className="centered-content">
                    <h1 className="font-40">Learn More About WAKIX</h1>
                </div>
            </section>
            <section className="form-wrap">
                <div className="centered-content">
                    <div className="content">
                        <div
                            className={`step-first ${
                                currStep === 1 ? "active" : ""
                            }`}
                            ref={formRef1}
                        >
                            <h2>
                                Sign up to learn more about WAKIX, patient
                                support programs, and available educational
                                resources
                            </h2>
                            <span className="sub-text asap font-21">
                                If you've been prescribed WAKIX:
                            </span>
                            <ul className="left-indent">
                                <li>Learn about what to expect</li>
                                <li>
                                    Get downloadable resources to help you set
                                    goals, talk to your healthcare provider, and
                                    stay motivated
                                </li>
                            </ul>
                            <span className="sub-text asap font-21">
                                If you haven't been prescribed WAKIX and want to
                                learn more:
                            </span>
                            <ul className="left-indent">
                                <li>Learn how WAKIX is thought to work</li>
                                <li>Find out what to expect from treatment</li>
                                <li>
                                    Get a guide for talking with your healthcare
                                    provider
                                </li>
                            </ul>
                            <span className="sub-text asap font-21">
                                If you are a parent/caregiver of a child 6 years
                                of age and older living with narcolepsy:
                            </span>
                            <ul className="left-indent pb-10">
                                <li>
                                    Discover why WAKIX might be a treatment
                                    option for your child
                                </li>
                                <li>
                                    Learn about what to expect when your child
                                    is on treatment
                                </li>
                            </ul>
                            <Controller
                                control={control1}
                                name="segment"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <div
                                        className={`form ${
                                            errors1.segment ? "error" : ""
                                        }`}
                                        onChange={(e) =>
                                            onChange(e.target.value)
                                        }
                                    >
                                        <h2>
                                            To provide you with the most
                                            appropriate resources, which of the
                                            following describes you best?*{" "}
                                        </h2>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option1">
                                                    <input
                                                        type="radio"
                                                        id="option1"
                                                        name="describeRadio"
                                                        group=""
                                                        value="Patient_Narcolepsy_CTP"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        I have a diagnosis of
                                                        narcolepsy with
                                                        cataplexy
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option2">
                                                    <input
                                                        type="radio"
                                                        id="option2"
                                                        name="describeRadio"
                                                        group=""
                                                        value="Patient_Narcolepsy"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        I have a diagnosis of
                                                        narcolepsy without
                                                        cataplexy
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option3">
                                                    <input
                                                        type="radio"
                                                        id="option3"
                                                        name="describeRadio"
                                                        group=""
                                                        value="Parent_Caregiver"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        I am a parent/caregiver
                                                        of a child with a
                                                        diagnosis of narcolepsy
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option4">
                                                    <input
                                                        type="radio"
                                                        id="option4"
                                                        name="describeRadio"
                                                        group=""
                                                        value="Suport_Network"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        I have a family member
                                                        or friend who has
                                                        narcolepsy
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>

                                        <span className="required-msg">
                                            Please choose an option.
                                        </span>
                                        <div className="btn-wrap">
                                            <button
                                                onClick={handleSubmit1((d) =>
                                                    handleStepNext(d)
                                                )}
                                                className="btn btn-primary"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        <span className="req">*Required</span>
                                    </div>
                                )}
                            />
                        </div>

                        <div
                            className={`step-second ${
                                currStep === 2.1 ? "active" : ""
                            }`}
                            ref={formRef2}
                        >
                            <Controller
                                control={control2a}
                                name="prescribed_wakix"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <div
                                        className={`form ${
                                            errors2a.prescribed_wakix
                                                ? "error"
                                                : ""
                                        }`}
                                        onChange={(e) =>
                                            onChange(e.target.value)
                                        }
                                    >
                                        <h2>
                                            Have you been prescribed WAKIX?*{" "}
                                        </h2>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_yes">
                                                    <input
                                                        type="radio"
                                                        id="option_yes"
                                                        name="youRadio"
                                                        group=""
                                                        value="yes"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Yes
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_no">
                                                    <input
                                                        type="radio"
                                                        id="option_no"
                                                        name="youRadio"
                                                        group=""
                                                        value="no"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        No
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>

                                        <span className="required-msg">
                                            Please choose an option.
                                        </span>
                                        <div className="btn-wrap">
                                            <button
                                                onClick={handleStepBack}
                                                className="btn btn-primary"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={handleSubmit2a((d) =>
                                                    handleStepNext(d)
                                                )}
                                                className="btn btn-primary"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        <span className="req">*Required</span>
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            className={`step-third ${
                                currStep === 2.2 ? "active" : ""
                            }`}
                            ref={formRef3}
                        >
                            <Controller
                                control={control2b}
                                name="loved_one_prescribed"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <div
                                        className={`form ${
                                            errors2b.loved_one_prescribed
                                                ? "error"
                                                : ""
                                        }`}
                                        onChange={(e) =>
                                            onChange(e.target.value)
                                        }
                                    >
                                        <h2>
                                            Has your loved one been prescribed
                                            WAKIX?*
                                        </h2>

                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_yes_love">
                                                    <input
                                                        type="radio"
                                                        id="option_yes_love"
                                                        name="loveRadio"
                                                        group=""
                                                        value="yes"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Yes
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_no_love">
                                                    <input
                                                        type="radio"
                                                        id="option_no_love"
                                                        name="loveRadio"
                                                        group=""
                                                        value="no"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        No
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_not_sure">
                                                    <input
                                                        type="radio"
                                                        id="option_not_sure"
                                                        name="loveRadio"
                                                        group=""
                                                        value="Unsure"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Not sure
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>

                                        <span className="required-msg">
                                            Please choose an option.
                                        </span>
                                        <div className="btn-wrap">
                                            <button
                                                onClick={handleStepBack}
                                                className="btn btn-primary"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={handleSubmit2b((d) =>
                                                    handleStepNext(d)
                                                )}
                                                className="btn btn-primary"
                                            >
                                                Continue
                                            </button>
                                        </div>

                                        <span className="req">*Required</span>
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            className={`step-third__three ${
                                currStep === 2.3 ? "active" : ""
                            }`}
                            ref={formRef3}
                        >
                            <Controller
                                control={control2c}
                                name="child_prescribed"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <div
                                        className={`form ${
                                            errors2c.child_prescribed
                                                ? "error"
                                                : ""
                                        }`}
                                        onChange={(e) =>
                                            onChange(e.target.value)
                                        }
                                    >
                                        <h2>
                                            Has your child been prescribed
                                            WAKIX?*
                                        </h2>

                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_yes_child">
                                                    <input
                                                        type="radio"
                                                        id="option_yes_child"
                                                        name="childRadio"
                                                        group=""
                                                        value="yes"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Yes
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div>
                                            <fieldset className="radio">
                                                <label for="option_no_child">
                                                    <input
                                                        type="radio"
                                                        id="option_no_child"
                                                        name="childRadio"
                                                        group=""
                                                        value="no"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        No
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>

                                        <span className="required-msg">
                                            Please choose an option.
                                        </span>
                                        <div className="btn-wrap">
                                            <button
                                                onClick={handleStepBack}
                                                className="btn btn-primary"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={handleSubmit2c((d) =>
                                                    handleStepNext(d)
                                                )}
                                                className="btn btn-primary"
                                            >
                                                Continue
                                            </button>
                                        </div>

                                        <span className="req">*Required</span>
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            className={`step-fourth ${
                                currStep === 3 ? "active" : ""
                            }`}
                        >
                            <form
                                className="form"
                                onSubmit={handleSubmit3(handleFormSubmit)}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <div
                                            className={`form-group ${
                                                errors3.first_name
                                                    ? "error"
                                                    : ""
                                            }`}
                                        >
                                            <input
                                                {...register3("first_name", {
                                                    required: true,
                                                })}
                                                type="text"
                                                name="first_name"
                                                placeholder="First Name*"
                                                className="form-control"
                                            />
                                            <span className="required-msg">
                                                Please enter a valid first name
                                            </span>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div
                                            className={`form-group ${
                                                errors3.last_name ? "error" : ""
                                            }`}
                                        >
                                            <input
                                                {...register3("last_name", {
                                                    required: true,
                                                })}
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name*"
                                                className="form-control"
                                            />
                                            <span className="required-msg">
                                                Please enter a valid last name
                                            </span>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div
                                            className={`form-group ${
                                                errors3.email_address
                                                    ? "error"
                                                    : ""
                                            }`}
                                        >
                                            <input
                                                {...register3("email_address", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message:
                                                            "Please enter a valid email",
                                                    },
                                                })}
                                                name="email_address"
                                                placeholder="Email Address* "
                                                className="form-control"
                                            />
                                            <span className="required-msg">
                                                Please enter a correctly
                                                formatted email address
                                            </span>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div
                                            className={`form-group ${
                                                errors3.confirmEmail
                                                    ? "error"
                                                    : ""
                                            }`}
                                        >
                                            <input
                                                {...register3("confirmEmail", {
                                                    required: true,
                                                    validate: (val) => {
                                                        if (
                                                            watch3(
                                                                "email_address"
                                                            ) !== val
                                                        ) {
                                                            return "Your Email do not match";
                                                        }
                                                    },
                                                })}
                                                name="confirmEmail"
                                                placeholder="Confirm Email Address* "
                                                className="form-control"
                                            />
                                            <span className="required-msg">
                                                Please confirm email address
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={9}>
                                        <div className="form-group address-field">
                                            <input
                                                type="text"
                                                placeholder="Address Line 1"
                                                className="form-control"
                                                {...register3("address_line_1")}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div class="tooltip">
                                            Why do we ask this?
                                            <span class="tooltiptext">
                                                We may occasionally send you
                                                resources in the mail.
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={9}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Address Line 2 (optional)"
                                                className="form-control"
                                                {...register3("address_line_2")}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={7}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="city"
                                                placeholder="City"
                                                className="form-control"
                                                {...register3("city")}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="form-group">
                                            <select
                                                className="form-control form-select"
                                                {...register3("state")}
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                >
                                                    State
                                                </option>
                                                <option value="AL">
                                                    Alabama
                                                </option>
                                                <option value="AK">
                                                    Alaska
                                                </option>
                                                <option value="AZ">
                                                    Arizona
                                                </option>
                                                <option value="AR">
                                                    Arkansas
                                                </option>
                                                <option value="CA">
                                                    California
                                                </option>
                                                <option value="CO">
                                                    Colorado
                                                </option>
                                                <option value="CT">
                                                    Connecticut
                                                </option>
                                                <option value="DE">
                                                    Delaware
                                                </option>
                                                <option value="DC">
                                                    District of Columbia
                                                </option>
                                                <option value="FL">
                                                    Florida
                                                </option>
                                                <option value="GA">
                                                    Georgia
                                                </option>
                                                <option value="HI">
                                                    Hawaii
                                                </option>
                                                <option value="ID">
                                                    Idaho
                                                </option>
                                                <option value="IL">
                                                    Illinois
                                                </option>
                                                <option value="IN">
                                                    Indiana
                                                </option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">
                                                    Kansas
                                                </option>
                                                <option value="KY">
                                                    Kentucky
                                                </option>
                                                <option value="LA">
                                                    Louisiana
                                                </option>
                                                <option value="ME">
                                                    Maine
                                                </option>
                                                <option value="MD">
                                                    Maryland
                                                </option>
                                                <option value="MA">
                                                    Massachusetts
                                                </option>
                                                <option value="MI">
                                                    Michigan
                                                </option>
                                                <option value="MN">
                                                    Minnesota
                                                </option>
                                                <option value="MS">
                                                    Mississippi
                                                </option>
                                                <option value="MO">
                                                    Missouri
                                                </option>
                                                <option value="MT">
                                                    Montana
                                                </option>
                                                <option value="NE">
                                                    Nebraska
                                                </option>
                                                <option value="NV">
                                                    Nevada
                                                </option>
                                                <option value="NH">
                                                    New Hampshire
                                                </option>
                                                <option value="NJ">
                                                    New Jersey
                                                </option>
                                                <option value="NM">
                                                    New Mexico
                                                </option>
                                                <option value="NY">
                                                    New York
                                                </option>
                                                <option value="NC">
                                                    North Carolina
                                                </option>
                                                <option value="ND">
                                                    North Dakota
                                                </option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">
                                                    Oklahoma
                                                </option>
                                                <option value="OR">
                                                    Oregon
                                                </option>
                                                <option value="PA">
                                                    Pennsylvania
                                                </option>
                                                <option value="RI">
                                                    Rhode Island
                                                </option>
                                                <option value="SC">
                                                    South Carolina
                                                </option>
                                                <option value="SD">
                                                    South Dakota
                                                </option>
                                                <option value="TN">
                                                    Tennessee
                                                </option>
                                                <option value="TX">
                                                    Texas
                                                </option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">
                                                    Vermont
                                                </option>
                                                <option value="VA">
                                                    Virginia
                                                </option>
                                                <option value="WA">
                                                    Washington
                                                </option>
                                                <option value="WV">
                                                    West Virginia
                                                </option>
                                                <option value="WI">
                                                    Wisconsin
                                                </option>
                                                <option value="WY">
                                                    Wyoming
                                                </option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                {...register3("zip_code")}
                                                placeholder="ZIP Code"
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <div className="form-group ph-number-field">
                                            <input
                                                type="text"
                                                {...register3("phone")}
                                                placeholder="Phone Number"
                                                className="form-control"
                                                onKeyDown={validateNumber}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div class="tooltip">
                                            Why do we ask this?
                                            <span class="tooltiptext">
                                                We reach out from time to time
                                                to offer educational resources
                                                via phone or text.
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <div
                                    className={`form-group form-check ${
                                        errors3.over_18 ? "error" : ""
                                    }`}
                                >
                                    <div className="wrap">
                                        <div>
                                            <Controller
                                                name="over_18"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <input
                                                        className="blue-checkbox"
                                                        type="checkbox"
                                                        {...field}
                                                        value="Yes"
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                        <span className="text">
                                            I am 18 years of age or older. I
                                            would like to receive information
                                            from Harmony Biosciences on
                                            educational programs, products, and
                                            services. By selecting Sign Up, I
                                            agree to the{" "}
                                            <Link to="/terms-of-use">
                                                Terms of Use
                                            </Link>{" "}
                                            in accordance with your{" "}
                                            <Link
                                                to="/privacy-policy"
                                                title="Read more about our Privacy Policy"
                                            >
                                                Privacy Policy
                                            </Link>
                                            .{" "}
                                        </span>
                                    </div>
                                    <span className="required-msg">
                                        Please check the box to continue
                                    </span>
                                </div>

                                <span className="foot-text">
                                    I understand that I may choose to no longer
                                    receive further communications from
                                    WAKIX.com by following the unsubscribe
                                    instructions on the communications I
                                    receive.
                                </span>

                                <div className="btn-wrap">
                                    <button
                                        onClick={handleStepBack}
                                        className="btn btn-primary"
                                    >
                                        Back
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        submit <BsChevronRight />
                                    </button>
                                </div>

                                <span className="req">*Required</span>
                            </form>
                        </div>
                        <div
                            className={`step-fifth ${
                                currStep === 5 ? "active" : ""
                            }`}
                        >
                            <h2>Thank you</h2>
                            <p>
                                You are now registered to receive more
                                information about WAKIX.{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </HcpLayout>
    );
};

export default SignUpPage;

export const Head = () => (
    <Seo
        title="Sign Up | WAKIX® (pitolisant)"
        description="Learn about WAKIX, a treatment for excessive daytime sleepiness (EDS) or cataplexy in adults with narcolepsy and EDS in children 6 years &amp; older with narcolepsy."
    />
);
