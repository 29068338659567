import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import { Col, Row } from "react-grid-system";
import Modal from "react-modal";
import * as styles from "./styles.module.css";
import "./find-a-healthcare-provider.scss";

export default function IndexPage({
    externalURL,
    children,
    onOkHandler,
    ...props
}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false);
    const [currentHref, setCurrentHref] = useState(null);

    const returnObj = { children: children, url: externalURL };

    const widgetHtml = '<directory-widget partner="508"></directory-widget>';
    const scriptHtml =
        '<script type="text/javascript" id="directory-widget" src="https://widget.doctor.com/directory_v2/app.js"></script>';

    const handleTermsClose = () => {
        setModalIsOpen(false);
    };

    const handleDisclaimerClose = () => {
        setDisclaimerIsOpen(false);
    };

    const handleDisclaimerContinue = () => {
        console.log(currentHref);
        onOkHandler && onOkHandler(returnObj);
        typeof window !== "undefined" && window.open(currentHref);
        setDisclaimerIsOpen(false);
    };

    /*   const handleClick = (ev) => {
    ev.preventDefault();
    return false;
  }; */

    /*  useEffect(() => {
    const anchors = document.querySelectorAll("ddc-a.ddc-external");
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", handleClick);
    });
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("click", handleClick);
      });
    };
  }, []); */

    useEffect(() => {
        const myEvent = (e) => {
            e.preventDefault();

            // console.log("Event Object,", e.target);

            // console.log("Event Object,", e.target.className);

            /* if (
        e.target.className.includes("ddc-card-directions-link") &&
        e.target.className.includes("ddc-a")
      ) {
        e.preventDefault();
      } */

            if (
                e.target.getElementsByTagName("ddc-a").length > 0 &&
                e.target.classList.contains("ddc-external")
            ) {
                e.preventDefault();
                return false;
            }

            if (e.target.className.indexOf("ddc-card-directions-link") != -1) {
                // setModalIsOpen(true);
            }

            if (e.target.className == "ddc-terms-and-conditions") {
                setModalIsOpen(true);
            }

            if (e.target.className.indexOf("ddc-external") != -1) {
                // console.log("target,", e.target);
                setDisclaimerIsOpen(true);
                setCurrentHref(e.target.href);
            }
        };
        const parent = window.document.getElementById("dfaWidget");

        parent.addEventListener("click", myEvent);

        return () => {
            parent.removeEventListener("click", myEvent);
        };
    }, []);

    return (
        <HcpLayout
            pageClass="hcp-main find-a-healthcare-provider"
            noStickyHCPLocatorCTA={true}
        >

            <div className="top-heading">
                <div className="centered-content">
                    <h1>
                        Ready to find a healthcare provider who has experience{" "}
                        <br className="show-on-desktop" />
                        treating adults with narcolepsy? Get started.*
                    </h1>
                </div>
            </div>
            <section className="banner-block no-pt no-pb">
                <div className="col bg-green"></div>
                <div className="centered-content">
                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div
                                dangerouslySetInnerHTML={{ __html: widgetHtml }}
                                id="dfaWidget"
                            />
                            <div
                                dangerouslySetInnerHTML={{ __html: scriptHtml }}
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <Modal
                isOpen={modalIsOpen}
                contentLabel="Terms Disclaimer Modal"
                className="hcp-locator-modal"
                overlayClassName="hcp-locator-modal-overlay"
            >
                <div
                    className={`${styles.overlayBackground} wakix-modal wakix-external-modal ${styles.overlay}`}
                >
                    <div
                        className={`${styles.modal} modal-container modal-terms`}
                    >
                        <div className="close">
                            <a className="cancel" onClick={handleTermsClose}>
                                ×
                            </a>
                        </div>
                        <div className="modal-title">Terms and Conditions</div>
                        <div className="modal-body">
                            <p>
                                Harmony Biosciences (Harmony) is providing this
                                service to help patients find Healthcare
                                Providers (HCPs) that specialize in treating
                                sleep disorders, like narcolepsy, in their area.
                                This list is updated on a periodic basis.
                            </p>
                            <p>
                                HCPs listed on this website are not affiliated
                                with Harmony. No fees have been received by
                                Harmony or paid to the HCPs for inclusion in
                                this locator directory, and inclusion in the
                                directory is not based on the volume or value of
                                WAKIX
                                <sup>&reg;</sup> (pitolisant) prescriptions
                                written by an HCP. Inclusion of an HCP in this
                                directory does not represent an endorsement by
                                or a referral, recommendation or verification of
                                qualifications or abilities from Harmony
                                regarding any given HCP over another, and the
                                absence of an HCP from the directory does not
                                imply anything about their credentials or
                                capabilities.
                            </p>
                            <p>
                                You are ultimately responsible for the selection
                                of a physician, and it is an important decision
                                that you should consider carefully. This locator
                                is just one source of information available to
                                you.
                            </p>
                            <p>
                                Harmony shall not be liable for any harm
                                resulting from reliance on information in this
                                directory.
                            </p>
                        </div>

                        <div className="modal-actions">
                            <button
                                className="btn btn-primary normal"
                                onClick={handleTermsClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={disclaimerIsOpen}
                contentLabel="Exit Disclaimer Modal"
                className="hcp-locator-modal"
                overlayClassName="hcp-locator-modal-overlay"
            >
                <div
                    className={`${styles.overlayBackground} wakix-modal wakix-external-modal ${styles.overlay}`}
                >
                    <div
                        className={`${styles.modal} modal-container modal-terms`}
                    >
                        <div className="close">
                            <a
                                className="cancel"
                                onClick={handleDisclaimerClose}
                            >
                                ×
                            </a>
                        </div>
                        <div className="modal-title">
                            You are now leaving the <nobr>WAKIX website</nobr>
                        </div>
                        <div className="modal-body">
                            <p>
                                You are now leaving the WAKIX website and moving
                                to an external website independently operated
                                and not managed by Harmony Biosciences
                                (Harmony).
                            </p>
                            <p>
                                By clicking “Agree and continue” below, you
                                acknowledge and agree that Harmony does not, in
                                any way, endorse or recommend www.doctor.com,
                                the qualifications of any healthcare provider
                                associated with www.doctor.com, or the quality
                                of medical care any of those providers can
                                provide.
                            </p>
                            <p>
                                You also acknowledge and agree that Harmony
                                makes no guarantees that using www.doctor.com
                                will result in your desired outcome. It is
                                wholly and solely your responsibility to assess
                                the qualifications of a potential healthcare
                                provider. Harmony recommends that you meet and
                                discuss the benefits and risks of all potential
                                treatments with the potential healthcare
                                provider.
                            </p>
                            <p>
                                HARMONY AND ITS AFFILIATES HEREBY DISCLAIM ANY
                                LIABILITY ARISING FROM YOUR USE AND/OR RELIANCE
                                ON THE INFORMATION CONTAINED ON THE
                                WWW.DOCTOR.COM WEBSITE.
                            </p>
                            <p>
                                Please be aware that www.doctor.com may have its
                                own set of terms and conditions and privacy
                                policy for which Harmony has no responsibility.
                            </p>
                            <p>
                                If you agree to the above, click "Agree and
                                continue" to proceed to the www.doctor.com
                                website. If you do not agree to the above, click
                                "Cancel" to remain on www.wakix.com.
                            </p>
                        </div>

                        <div className="modal-actions">
                            <button
                                className="btn btn-primary normal"
                                onClick={handleDisclaimerClose}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={handleDisclaimerContinue}
                            >
                                Agree and continue
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </HcpLayout>
    );
}

IndexPage.propTypes = {
    onOkHandler: PropTypes.func,
};

export const Head = () => (
    <Seo
        title="Healthcare Provider Locator | WAKIX® (pitolisant)"
        description="Find a healthcare provider who has experience treating adults with narcolepsy. Use the tool to
  locate a healthcare provider near you."
        keywords=""
    />
);
