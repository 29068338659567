import * as React from "react";
import HcpLayout from "../../components/hcp/layout";
import { Seo } from "../../components/seo";
import { Link } from "gatsby";
import useDeviceSize from "../../hooks/use-device-size";
import { TfiAngleRight } from "react-icons/tfi";
import bannerImg from "../../images/resources/hero-resources-overview.webp";
import bannerImgMobile from "../../images/resources/hero-resources-overview-mobile.webp";
import icon1 from "../../images/icons/icon-resource-download.svg";
import icon2 from "../../images/icons/icons-support-grp.svg";
import icon3 from "../../images/icons/icons-faqs.svg";
import icon4 from "../../images/icons/icon-hcp-locator.svg";
import SocialIconBlock from "../../components/socialIconBlock";

import "./resources-and-support.scss";
import LazyLoad from "react-lazy-load";
import { StaticImage } from "gatsby-plugin-image";

const ResourcesPage = () => {
  const deviceSizes = useDeviceSize();
  return (
    <HcpLayout pageClass="resources-page">
      <div className="top-heading">
        <div className="centered-content">
          <h2>Resources Overview</h2>
        </div>
      </div>
      <div className="hero-wrapper row-full">
        <div className="hero-content-wrapper">
          <div className="hero-banner inner bg-cover resources">
            {deviceSizes?.smUp ? (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={bannerImg}
                  alt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with his phone"
                />
              </LazyLoad>
            ) : (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={bannerImgMobile}
                  alt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with his phone"
                />
              </LazyLoad>
            )}
            <div className="centered-content">
              <div className="hero-name on-right">
                <StaticImage
                  src="../../images/resources/hero-resources-overview-name.webp"
                  alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                  width={231}
                  height={120}
                  quality={95}
                  layout="fixed"
                  placeholder="none"
                  style={{ transition: 'opacity 0.5s' }}
                  imgStyle={{ opacity: 1 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="inner-first page-info-text">
        <div className="centered-content">
          <h1>Resources</h1>
          <h2>
            Hear from other people living with narcolepsy, learn more about
            WAKIX, and find tips for starting the conversation with your
            healthcare provider
          </h2>
        </div>
      </section>
      <section className="card-boxes-wrap no-pt">
        <div className="centered-content">
          <div className="card-boxes">
            <div className="card-boxes_item">
              <div className="card-boxes_item__inner">
                <div className="card-boxes_item__inner___heading">
                  <h3 className="font-28">Tools to start the conversation</h3>
                </div>
                <div className="card-boxes_item__inner___icon">
                  <img
                    src={icon1}
                    className="icon"
                    alt="Resources download icon"
                  />
                </div>
                <div className="btn-wrap">
                  <Link
                    to="/resources-and-support/downloads"
                    className="btn btn-primary btn-ptimary--md normal resource-btn"
                  >
                    Access resources
                    <TfiAngleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-boxes_item">
              <div className="card-boxes_item__inner">
                <div className="card-boxes_item__inner___heading">
                  <h3 className="font-28">
                    Connect with others in the narcolepsy community
                  </h3>
                </div>
                <div className="card-boxes_item__inner___icon">
                  <img
                    src={icon2}
                    className="icon"
                    alt="Connecting with others in the narcolepsy community icon "
                  />
                </div>
                <div className="btn-wrap">
                  <Link
                    to="/resources-and-support/support-organizations"
                    className="btn btn-primary btn-primary--md resource-btn"
                  >
                    Connect
                    <TfiAngleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-boxes_item">
              <div className="card-boxes_item__inner green-card">
                <div className="card-boxes_item__inner___heading">
                  <h3 className="font-28">Frequently asked questions</h3>
                </div>
                <div className="card-boxes_item__inner___icon">
                  <img
                    src={icon3}
                    className="icon"
                    alt="Frequently asked questions talk bubble icon"
                  />
                </div>
                <div className="btn-wrap">
                  <Link
                    to="/resources-and-support/frequently-asked-questions"
                    className="btn btn-primary btn-primary--md resource-btn"
                  >
                    Find answers
                    <TfiAngleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-boxes_item">
              <div className="card-boxes_item__inner gray-card">
                <div className="card-boxes_item__inner___heading">
                  <h3 className="font-28">
                    Find a healthcare provider
                  </h3>
                </div>
                <div className="card-boxes_item__inner___icon">
                  <img
                    src={icon4}
                    className="icon"
                    alt="Find a healthcare provider who has experience treating adults with narcolepsy icon"
                  />
                </div>
                <div className="btn-wrap">
                  <a href="/resources-and-support/find-a-healthcare-provider" class="btn btn-primary btn-primary--md resource-btn">
                    Start now
                    <TfiAngleRight />
                  </a>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
      <SocialIconBlock />
    </HcpLayout>
  );
};

export default ResourcesPage;

export const Head = () => (
  <Seo
    title="Resources and Support | WAKIX® (pitolisant)"
    description="Learn more about narcolepsy and WAKIX. Find discussion guides for talking with your healthcare provider."
    keywords="WAKIX resources, WAKIX information, WAKIX tools, Pitolisant resources, Pitolisant information, Pitolisant tools, Narcolepsy resources, Narcolepsy support, Narcolepsy support groups"
  />
);
