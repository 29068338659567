import React from "react";
import LazyLoad from "react-lazy-load";
import useDeviceSize from "../../hooks/use-device-size";
// import ViolatorCard from '../violator-card';
import "./inner-banner.scss";

const InnerBanner = ({
    topHeading,
    bannerClass = "",
    bannerHeroImg,
    bannerHeroImgClass = "",
    bannerImgAlt,
    bannerHeroImgMobile,
    bannerHeroImgMobileClass = "",
    heroNameImg,
    heroNameClass = "",
}) => {
    return (
        <>
            {/* <ViolatorCard
                className='hasViolatorBanner'
            /> */}

            <div className="top-heading">
                <div className="centered-content">
                    <h2 dangerouslySetInnerHTML={{ __html: topHeading }}></h2>
                </div>
            </div>

            <div className="hero-wrapper row-full hasViolatorBanner">
                <div className="hero-content-wrapper">
                    <div
                        className={`hero-banner inner bg-cover ${bannerClass}`}
                    >
                        <div className="banner-desktop-img">
                            <img
                                src={bannerHeroImg}
                                alt={bannerImgAlt}
                                className={bannerHeroImgClass}
                                loading="eager"
                            />
                        </div>
                        <div className="banner-mob-img">
                            <img
                                src={bannerHeroImgMobile}
                                alt={bannerImgAlt}
                                className={bannerHeroImgMobileClass}
                                loading="eager"
                            />
                        </div>
                        <div className="centered-content">
                            <div className={`hero-name ${heroNameClass}`}>
                                {heroNameImg}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InnerBanner;
