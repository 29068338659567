import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Col, Row } from "react-grid-system";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PersonalVideo from "../components/cards/personalVideo";
import TaraThumb from "../images/icons/thumb-tara-video.webp";
import TaraSliderThumbImg1 from "../images/personal-stories/tara-slider-img-1.webp";
import useDeviceSize from "../hooks/use-device-size";

function StoriesSlider({ slidesArray, className = "", ...props }) {
    const settings = {
        showArrows: true,
        showStatus: false,
        showThumbs: false,
        infiniteLoop: false,
        autoPlay: false,
        preventMovementUntilSwipeScrollTolerance: true,
        swipeScrollTolerance: 50,

        renderArrowPrev: (onClickHandler, hasPrev, label) =>
            hasPrev && (
                <button
                    type="button"
                    aria-label="previous slide / item"
                    onClick={onClickHandler}
                    title={label}
                    className="control-arrow control-prev"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7.264"
                        height="12.264"
                        viewBox="0 0 7.264 12.264"
                    >
                        <g
                            id="Carat"
                            transform="translate(6.632 11.632) rotate(-90)"
                        >
                            <path
                                id="Line"
                                d="M.429-.429,5.571-5.571"
                                fill="none"
                                stroke="#005db9"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                            />
                            <path
                                id="Line_Copy"
                                data-name="Line Copy"
                                d="M-.429-.429-5.571-5.571"
                                transform="translate(11)"
                                fill="none"
                                stroke="#005db9"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                            />
                        </g>
                    </svg>
                </button>
            ),
        renderArrowNext: (onClickHandler, hasNext, label) =>
            hasNext && (
                <button
                    type="button"
                    aria-label="next slide / item"
                    onClick={onClickHandler}
                    title={label}
                    className="control-arrow control-next"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7.264"
                        height="12.264"
                        viewBox="0 0 7.264 12.264"
                    >
                        <g
                            id="Carat"
                            transform="translate(0.632 0.632) rotate(90)"
                        >
                            <path
                                id="Line"
                                d="M.429-.429,5.571-5.571"
                                fill="none"
                                stroke="#005db9"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                            />
                            <path
                                id="Line_Copy"
                                data-name="Line Copy"
                                d="M-.429-.429-5.571-5.571"
                                transform="translate(11)"
                                fill="none"
                                stroke="#005db9"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                            />
                        </g>
                    </svg>
                </button>
            ),
    };

    return (
        <div {...props} className={`stories-slider ${className}`}>
            <Carousel {...settings}>
                <Slider1 />
                <Slider2 />
            </Carousel>
        </div>
    );
}

export default StoriesSlider;

function Slider1() {
    const deviceSizes = useDeviceSize();

    return (
        <Row className="row align-items-center">
            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                <div className="text" id="personal-story-tara">
                    <AnchorLink href="#personal-story-tara" />
                    <h2>Meet Tara</h2>
                    <h3>
                        {" "}
                        Healthcare worker, narcolepsy advocate, {deviceSizes?.mdUp &&<br />}
                        aunt, dog mom
                    </h3>
                    <p>
                        Hear how Tara is adjusting to life after graduating and
                        moving to a new state. She continues to take WAKIX to
                        help her manage excessive daytime sleepiness and has
                        regular check-ins with her current doctor on how she is
                        doing.
                    </p>
                </div>
            </Col>

            <Col
                lg={6}
                xl={7}
                order={{ xs: 1, lg: 2 }}
                className="two-column__video"
            >
                <div className="card-video-wrap">
                    <PersonalVideo
                        videoId="1032033310"
                        videoIdParam=""
                        videoThumbnail={TaraSliderThumbImg1}
                        altText={
                            "Video thumbnail of Tara, a real person with narcolepsy with cataplexy taking WAKIX"
                        }
                        customTagText="Tara video"
                    />
                </div>
            </Col>
        </Row>
    );
}

function Slider2() {
    const deviceSizes = useDeviceSize();

    return (
        <Row className="row align-items-center">
            <Col lg={6} xl={5} order={{ xs: 2, lg: 1 }}>
                <div className="text" id="personal-story-tara">
                    <h2>Meet Tara</h2>
                    <h3>
                        {" "}
                        Healthcare worker, narcolepsy advocate, {deviceSizes?.mdUp &&<br />}
                        aunt, dog mom
                    </h3>
                    <p>
                        After receiving her narcolepsy diagnosis, Tara felt both
                        relieved and nervous. Hear how she got started on WAKIX
                        and what she noticed about her excessive daytime
                        sleepiness as time went on.
                    </p>
                </div>
            </Col>

            <Col
                lg={6}
                xl={7}
                order={{ xs: 1, lg: 2 }}
                className="two-column__video"
            >
                <div className="card-video-wrap">
                    <PersonalVideo
                        videoId="1006599816"
                        videoIdParam="7a898e7a4f"
                        videoThumbnail={TaraThumb}
                        altText={
                            "Video thumbnail of Tara, a real person with narcolepsy with cataplexy taking WAKIX"
                        }
                        customTagText="Tara video"
                    />
                </div>
            </Col>
        </Row>
    );
}
