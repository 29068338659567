import React from "react";
import HcpLayout from "../../../../components/hcp/layout";
import { Seo } from "../../../../components/seo";
import { Row, Col } from "react-grid-system";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import resourceImg from "../../../../images/what-should-i-except/wakix-for-you-cta-desktop.webp";
import resourceMobileImg from "../../../../images/what-should-i-except/wakix-for-you-cta-mobile.webp";
import expectationsImg from "../../../../images/why-wakix/desktop-expectation.webp";
import expectationsMobileImg from "../../../../images/why-wakix/cta-what-you-expect-mobile.webp";
import TabAccordion from "../../../../components/tabAccordion";
import TabAccordionList from "../../../../components/tabAccordion/tabAccordionList";
import TabAccordionCustomTab from "../../../../components/tabAccordion/tabAccordionCustomTab";
import TabAccordionPanel from "../../../../components/tabAccordion/tabAccordionPanel";
import CustomAccordion from "../../../../components/Accordion/customAccordion";
import reductionIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_1-2 Reduction.svg";
import reducedSleepIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Reduced Sleepiness.svg";
import readingIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Reading.svg";
import watchingIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Watching TV.svg";
import sittingWatchingIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Sitting Talking.svg";
import kneesIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Knees buckling.svg";
import handsIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Hand weakness.svg";
import jawIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Jaw sagging.svg";
import nauseaIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Nausea.svg";
import insomniaIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Insomnia.svg";
import anxietyIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Anxiety.svg";
import tableDesktopImg from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effects Table_Desktop.svg";
import tableMobileImg from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effects Table_Mobile.svg";
import reportIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Report.svg";
import talkIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Talk.svg";
import sideEffectsImg from "../../../../images/WAKIX-clinical-trial-results/side-effects-1.webp";
import sideEffectsImgMobile from "../../../../images/WAKIX-clinical-trial-results/side-effects-mobile-1.webp";
import SocialIconBlock from "../../../../components/socialIconBlock";
import bannerImg from "../../../../images/why-wakix/hero-about.webp";
import bannerImgMobile from "../../../../images/why-wakix/hero-about-mobile.webp";
import useDeviceSize from "../../../../hooks/use-device-size";
import { StaticImage } from "gatsby-plugin-image";
import InnerBanner from "../../../../components/InnerBanner";
import "./adult-clinical-trial-results.scss";

const WakixAdultClinicalTrialResults = () => {
  const deviceSizes = useDeviceSize();

  return (
    <HcpLayout pageClass="hcp-main wakix-clinical-trial-results wakix-adult-clinical-trial-results">
      <InnerBanner
        topHeading="EDS & Cataplexy in Adults"
        bannerHeroImg={bannerImg}
        bannerHeroImgMobile={bannerImgMobile}
        bannerImgAlt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with friends"
        heroNameImg={
          <StaticImage
            src="../../../../images/why-wakix/hero-about-name.webp"
            alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
            width={347}
            height={138}
            quality={95}
            layout="fixed"
            placeholder="none"
            style={{ transition: "opacity 0.5s" }}
            imgStyle={{ opacity: 1 }}
          />
        }
      />

      <section className="inner-first no-pb">
        <div className="clinical-trial__info">
          <div className="centered-content">
            <h1 className="font-40 mb-12">
              Results With WAKIX in the Adult Clinical Trials
            </h1>
            <h2 className="asap font-25">
              <strong>
                Significantly reduced excessive daytime sleepiness (EDS)
              </strong>{" "}
              and <strong>significantly fewer cataplexy attacks</strong> in
              adults with narcolepsy compared with placebo
            </h2>
          </div>
        </div>
      </section>

      <section className="tab-block gray-bg no-pt">
        <div className="centered-content">
          <TabAccordion>
            <TabAccordionList>
              <TabAccordionCustomTab>EDS Results</TabAccordionCustomTab>
              <TabAccordionCustomTab>Cataplexy Results</TabAccordionCustomTab>
              <TabAccordionCustomTab>Side Effects</TabAccordionCustomTab>
            </TabAccordionList>
            <TabAccordionPanel id="tab1">
              <div className="clinicial-content">
                <h3 className="asap font-25 blue">
                  WAKIX significantly reduced excessive daytime sleepiness (EDS)
                  compared with placebo
                </h3>
                <p className="btm-spacer">
                  WAKIX was studied for the treatment of <strong>EDS</strong>{" "}
                  in&nbsp;
                  <strong>two clinical trials</strong>
                  &nbsp;(Trial 1 and Trial 2) in{" "}
                  <strong>159 adult patients with narcolepsy</strong>. WAKIX was
                  compared with placebo (sugar pill) using the{" "}
                  <span class="tooltip">
                    Epworth Sleepiness Scale (ESS)
                    <span class="tooltip-text white-bg">
                      A questionnaire that measures the level of EDS by seeing
                      how likely you are to doze or fall asleep in 8 everyday
                      situations.
                    </span>
                  </span>
                  .
                </p>
                <CustomAccordion
                  data={{
                    heading:
                      "Learn more about the clinical trials for EDS in adult patients with narcolepsy",
                    content: `
                  <ul class="blueDot-list pt-0">
                  <li>Trial 1 was 8 weeks and included 61 adults with narcolepsy with or without cataplexy (31 people were treated with WAKIX and 30 received placebo). About 80% of the people in the trial had cataplexy and about 20% did not.</li>
                  <li>Trial 2 was also 8 weeks and included 98 adults with narcolepsy with or without cataplexy (66 people were treated with WAKIX and 32 received placebo). In this trial, 75% of people had cataplexy and 25% did not.</li>
                </ul>
                  `,
                  }}
                />
                <h4 className="font-21 asap">
                  At the start of the trials, the majority of patients reported
                  severe EDS
                </h4>
                <p>
                  Their{" "}
                  <span className="tooltip">
                    ESS
                    <span className="tooltip-text white-bg">
                      Epworth Sleepiness Scale (ESS): A questionnaire that
                      measures the level of EDS by seeing how likely you are to
                      doze or fall asleep in 8 everyday situations.
                    </span>
                  </span>{" "}
                  scores were 16 or higher, which means they had a high chance
                  of dozing or falling asleep in everyday situations, such as:
                </p>
                <Row className="row symptoms">
                  <Col lg={4}>
                    <div className="card-icon">
                      <img
                        src={readingIcon}
                        alt="Falling asleep while reading icon"
                      />
                      <p className="asap asap-bold font-21">Reading</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img
                        src={watchingIcon}
                        alt="Falling asleep while watching TV icon"
                      />
                      <p className="asap asap-bold font-21">Watching TV</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img
                        src={sittingWatchingIcon}
                        alt="Falling asleep while talking to someone icon"
                      />
                      <p className="asap asap-bold font-21">
                        Sitting and talking
                      </p>
                    </div>
                  </Col>
                </Row>
                <h4 className="font-21 asap">At the end of the trials…</h4>
                <ul className="icon-list">
                  <li>
                    <div style={{ width: "21%" }} className="icon">
                      <img
                        src={reducedSleepIcon}
                        style={{ width: "100%" }}
                        alt="Reduced sleepiness icon"
                      />
                    </div>
                    <div className="text">
                      <h4 className="font-21 asap">
                        ESS scores were significantly improved for patients
                        taking WAKIX compared with placebo
                      </h4>
                      <p>
                        This means patients taking WAKIX reported a{" "}
                        <strong>
                          significantly lower ESS score, or lower chance of
                          dozing or falling asleep in everyday situations
                        </strong>{" "}
                        compared with placebo.
                      </p>
                    </div>
                  </li>
                  <li>
                    <p>
                      Everyone responds to medication differently. Individual
                      responses varied in the clinical trials.
                    </p>
                  </li>
                </ul>
              </div>
            </TabAccordionPanel>
            <TabAccordionPanel id="tab2">
              <div className="clinicial-content">
                <h3 className="asap font-25 blue">
                  Significantly fewer cataplexy attacks with WAKIX compared with
                  placebo
                </h3>
                <p className="btm-spacer">
                  WAKIX was studied for the treatment of{" "}
                  <strong>cataplexy</strong>{" "}
                  <strong>in two clinical trials</strong> (Trial 3 and Trial 1)
                  in <strong>154 adult patients with narcolepsy</strong>. WAKIX
                  was compared with placebo (sugar pill) by measuring how often
                  patients in the trials experienced <strong>cataplexy</strong>.
                  One trial measured the number of{" "}
                  <strong>weekly cataplexy attacks</strong> and the other trial
                  measured the number of{" "}
                  <strong>daily cataplexy attacks</strong>.
                </p>
                <CustomAccordion
                  data={{
                    heading:
                      "Learn more about the clinical trials for cataplexy in adult patients with narcolepsy",
                    content: `
                  <ul class="blueDot-list pt-0">
                  <li>Trial 3 was 7 weeks and included 105 adults with narcolepsy with cataplexy (54 people were treated with WAKIX and 51 received placebo). This trial measured the number of <strong>weekly cataplexy attacks</strong>. </li>
                  <li>Trial 1 was 8 weeks and included 49 adults with narcolepsy with cataplexy (25 people were treated with WAKIX and 24 received placebo). This trial measured the number of <strong>daily cataplexy attacks</strong>. </li>
                </ul>
                  `,
                  }}
                />
                <h4 className="font-21 asap">
                  At the start of the trial measuring weekly cataplexy attacks,
                  on average, patients had about 8 cataplexy attacks per week
                </h4>
                <p>Examples of cataplexy include:</p>
                <Row className="row symptoms">
                  <Col lg={4}>
                    <div className="card-icon">
                      <img
                        src={kneesIcon}
                        alt="Knees buckling cataplexy icon"
                      />
                      <p className="asap asap-bold font-21">Knees buckling</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img src={handsIcon} alt="Hand weakness cataplexy icon" />
                      <p className="asap asap-bold font-21">
                        Hand weakness
                        <span className="small-fnt acumen font-16">
                          (dropping objects)
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img src={jawIcon} alt="Jaw sagging cataplexy icon" />
                      <p className="asap asap-bold font-21">Jaw sagging</p>
                    </div>
                  </Col>
                </Row>
                <h4 className="font-21 asap">At the end of this trial…</h4>
                <ul className="icon-list">
                  <li>
                    <div style={{ width: "21%" }} className="icon">
                      <img
                        src={reductionIcon}
                        alt="Reduced cataplexy attacks icon"
                      />
                    </div>
                    <div className="text">
                      <h4 className="font-21 asap">
                        Patients taking WAKIX had about half as many cataplexy
                        attacks per week compared with patients taking placebo
                      </h4>
                    </div>
                  </li>
                </ul>
                <p
                  style={{
                    paddingLeft: "10px",
                    paddingBottom: "25px",
                  }}
                >
                  Everyone responds to medication differently. Individual
                  responses varied in the clinical trials.
                </p>
              </div>
            </TabAccordionPanel>
            <TabAccordionPanel id="tab3">
              <div className="clinicial-content">
                <h3 className="asap font-25 blue">
                  WAKIX side effects in the adult clinical trials
                </h3>
                <p>
                  In clinical trials of adult patients with narcolepsy, the most
                  common side effects of WAKIX that were at least twice the rate
                  of placebo (sugar pill) were:
                </p>
                <Row className="row symptoms">
                  <Col lg={4}>
                    <div className="card-icon">
                      <img src={insomniaIcon} alt="Insomnia icon" />
                      <p className="asap asap-bold font-21">Insomnia</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img src={nauseaIcon} alt="Nausea icon" />
                      <p className="asap asap-bold font-21">Nausea</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img src={anxietyIcon} alt="Anxiety icon" />
                      <p className="asap asap-bold font-21">Anxiety</p>
                    </div>
                  </Col>
                </Row>
                <div className="center-title text-center">
                  <h4 className="font-21 asap">
                    Side effects that occurred in at least 5% of patients
                    treated with WAKIX and more frequently than placebo
                  </h4>
                </div>
                <div className="media">
                  <img
                    src={tableDesktopImg}
                    alt="Table showing side effects of WAKIX in adult clinical trials"
                    className="desktop-view"
                  />
                  <img
                    src={tableMobileImg}
                    alt="Table showing side effects of WAKIX in adult clinical trials"
                    className="mobile-view"
                  />
                </div>
                <p className="font-14">
                  <i>
                    n indicates the number of patients who were treated with WAKIX or received placebo.
                  </i>
                </p>
                <ul className="icon-list">
                  <li>
                    <div className="icon medium">
                      <img src={reportIcon} alt="Clipboard and pen icon" />
                    </div>
                    <div className="text">
                      <p>
                        Other side effects of WAKIX in the adult clinical trials
                        included increased heart rate, hallucinations,
                        irritability, abdominal pain, sleep disturbance, and
                        decreased appetite.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon medium">
                      <img src={talkIcon} alt="Talk bubble icon" />
                    </div>
                    <div className="text">
                      <p>
                        <strong>
                          These are not all the possible side effects of WAKIX.{" "}
                        </strong>{" "}
                        Call your healthcare provider for medical advice about side effects.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="media text-center-mobile">
                  {deviceSizes?.smUp ? (
                    <img
                      src={sideEffectsImg}
                      alt="Infographic showing discontinuation rates in adult clinical trials"
                    />
                  ) : (
                    <img
                      src={sideEffectsImgMobile}
                      alt="Infographic showing discontinuation rates in adult clinical trials"
                    />
                  )}
                </div>
              </div>
            </TabAccordionPanel>
          </TabAccordion>
        </div>
        <div className="centered-content">
          <div className="btn-wrap btn-spacer mobile-widthFull">
            <Link
              to="/about-wakix/wakix-clinical-trials/pediatric-clinical-trial-results"
              className="btn btn-primary btn-primaryAlt"
            >
              <span className="text">
                See results in children <nobr>6-17 years old</nobr>
              </span>
              <span className="icon">
                <TfiAngleRight />
              </span>
            </Link>
          </div>
        </div>
      </section>

      <section className="banner-block two-columns no-pt no-pb">
        <div className="col bg-img">
          <div className="inner bg-cover cta-img-hgt inner--alt">
            <img
              src={expectationsImg}
              className="two-col__desktopImg img-flip"
              alt="Kristen discussing WAKIX and symptoms with her healthcare provider"
            />
            <img
              src={expectationsMobileImg}
              className="two-col__mobileImg"
              alt="Kristen discussing WAKIX and symptoms with her healthcare provider"
            />
            <div className="content">
              <h3 className="asap">
                What to expect <br className="show-mobile" />
                with WAKIX
              </h3>
            </div>
            <div className="btn-wrap btn-wrap-align-left">
              <Link
                to="/starting-wakix/what-to-expect"
                className="btn btn-primary btn-primary--md"
              >
                Find out
                <TfiAngleRight />
              </Link>
            </div>
          </div>
        </div>
        <div className="col bg-img">
          <div className="inner bg-cover cta-img-hgt">
            {deviceSizes?.smUp ? (
              <img
                src={resourceImg}
                className="two-col img-flip"
                alt="WAKIX for You Patient Case Manager with headset"
              />
            ) : (
              <img
                src={resourceMobileImg}
                className="two-col"
                alt="WAKIX for You Patient Case Manager with headset"
              />
            )}
            <div className="content cout-left-spacer">
              <h3 className="asap green asap-bold">
                The <span className="italic asap asap-bold">WAKIX for You</span>{" "}
                patient support program is <br className="show-mobile" />
                here to help
              </h3>
            </div>
            <div className="btn-wrap cout-left-spacer">
              <Link
                to="/wakix-for-you-support-program"
                className="btn btn-primary btn-primary--md"
              >
                View available support
                <TfiAngleRight />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <SocialIconBlock />
    </HcpLayout>
  );
};

export default WakixAdultClinicalTrialResults;

export const Head = () => (
  <Seo
    title="Adult Clinical Trial Results | WAKIX® (pitolisant)"
    description="WAKIX was studied for the treatment of excessive daytime sleepiness (EDS) & cataplexy in adults with narcolepsy. Learn about the clinical trial results."
    keywords="WAKIX clinical trials, pitolisant clinical trials, WAKIX clinical trial results, pitolisant clinical trial results, WAKIX clinical trial before after, pitolisant clinical trial before after, WAKIX side effects, pitolisant side effects"
  />
);
