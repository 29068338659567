import * as React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import "./tips-for-living-with-narcolepsy.scss";
import { Link } from "gatsby";
import { Col, Row } from "react-grid-system";
import useDeviceSize from "../../../hooks/use-device-size";
import CustomVideo from "../../../components/cards/customVideo";
import { TfiAngleRight } from "react-icons/tfi";
import videoThumbnail from "../../../images/living-with-narcolepsy/tips-video-thumbnail.webp";
import icon1 from "../../../images/icons/icon-tips-journal.svg";
import icon2 from "../../../images/icons/icon-tips-naps.svg";
import icon3 from "../../../images/icons/icon-tips-alcohol.svg";
import icon4 from "../../../images/icons/icon-tips-regular-schedule.svg";
import icon5 from "../../../images/icons/icon-tips-caffeine.svg";
import icon6 from "../../../images/icons/icon-tips-stress.svg";
import resourcesImg from "../../../images/tips-for-living-with-narcolepsy/cta-resources.webp";
import resourcesMobileImg from "../../../images/tips-for-living-with-narcolepsy/cta-resources-mobile.webp";
import supportIcon from "../../../images/icons/icon-support-personal-stories.svg";
import SocialIconBlock from "../../../components/socialIconBlock";
import bannerImg from "../../../images/living-with-narcolepsy/hero-narcolepsy.webp";
import bannerImgMobile from "../../../images/living-with-narcolepsy/hero-narcolepsy-mobile.webp";
import taraThumbnail from "../../../images/tips-for-living-with-narcolepsy/tara-unbranded-video-thumbnail.webp";

const TipForLivingWithNarcolepsy = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout pageClass="tips-for-living" noStickyHCPLocatorCTA={true}>
            <div className="top-heading">
                <div className="centered-content">
                    <h2 className="blue">Tips for Living With Narcolepsy</h2>
                </div>
            </div>

            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover narcolepsy">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                alt="Four people living with narcolepsy with arms around each other looking into the distance"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                alt="Four people living with narcolepsy with arms around each other looking into the distance"
                            />
                        )}
                    </div>
                </div>
            </div>

            <section className="inner-first no-pb">
                <div className="centered-content">
                    <div className="page-heading ph-btm">
                        <h1 className="blue font-40">
                            Narcolepsy Shouldn’t Stop People From Living Their
                            Life
                        </h1>
                        <p>
                            Living with narcolepsy is difficult, and finding
                            ways to manage your excessive daytime sleepiness
                            (EDS) or cataplexy can be a challenge.
                        </p>
                    </div>
                </div>
            </section>

            <section className="two-column" id="tips-narcolepsy">
                <div className="centered-content">
                    <Row align="center" className="row">
                        <Col lg={6} xl={7} className="two-column__video">
                            <div className="col video-wrap fit">
                                <div className="card-video-wrap">
                                    <CustomVideo
                                        videoId="1006593807"
                                        videoIdParam="3a0103e9d6"
                                        videoThumbnail={videoThumbnail}
                                        altText={
                                            "Tips for living with narcolepsy video thumbnail"
                                        }
                                        customTagText="Tips for living with narcolepsy video"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xl={5}>
                            <div className="two-column__content">
                                <h3 className="pro-black black">
                                    Tips from others living with narcolepsy
                                </h3>
                                <p>
                                    Hear other people living with narcolepsy
                                    share their tips for managing day to day
                                    with narcolepsy.
                                </p>
                                <div className="btn-wrap">
                                    <Link
                                        to="/wakix-personal-stories"
                                        className="btn btn-primary btn-primary--md"
                                    >
                                        Hear more real-life stories
                                        <TfiAngleRight />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row align="center" className="row">
                        <Col lg={6} xl={7} className="two-column__video">
                            <div className="col video-wrap fit">
                                <div className="card-video-wrap">
                                    <CustomVideo
                                        videoId="1023960831"
                                        videoIdParam="c667a81877"
                                        videoThumbnail={taraThumbnail}
                                        altText={
                                            "Video thumbnail of Tara, a real person with narcolepsy with cataplexy"
                                        }
                                        customTagText="Adapting to a New Routine video featuring Tara 2024"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xl={5}>
                            <div className="two-column__content">
                                <h3 className="pro-black black">
                                    Adapting to a new routine
                                </h3>
                                <p>
                                    Hear Tara's story about managing daily life
                                    with narcolepsy and how she adjusted to new
                                    routines after moving to a different state.
                                </p>
                                <div className="btn-wrap">
                                    <a
                                        href="/pdf/WAKIX-tips-college-students-narcolepsy.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary btn-primary--md dwn-btn"
                                    >
                                        Download tips
                                        <svg
                                            id="download_arrow"
                                            data-name="download arrow"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            width="21"
                                            height="22"
                                            viewBox="0 0 21 22"
                                        >
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <path
                                                        id="Clip_4"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                </clipPath>
                                            </defs>
                                            <path
                                                id="Fill_1"
                                                data-name="Fill 1"
                                                d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                transform="translate(0 17)"
                                                fill="#fff"
                                            />
                                            <g
                                                id="Group_5"
                                                data-name="Group 5"
                                                transform="translate(9)"
                                            >
                                                <path
                                                    id="Clip_4-2"
                                                    data-name="Clip 4"
                                                    d="M0,0H2V16H0Z"
                                                    fill="none"
                                                />
                                                <g
                                                    id="Group_5-2"
                                                    data-name="Group 5"
                                                    clipPath="url(#clip-path)"
                                                >
                                                    <path
                                                        id="Fill_3"
                                                        data-name="Fill 3"
                                                        d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </g>
                                            <path
                                                id="Fill_6"
                                                data-name="Fill 6"
                                                d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                transform="translate(3 9)"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="gray-bg manage-day">
                <div className="centered-content">
                    <div className="block-title">
                        <h2>
                            Managing day to day as an adult living with
                            narcolepsy
                        </h2>
                    </div>
                    <Row className="row">
                        <Col lg={6}>
                            <div className="icon-items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon-items__icon">
                                            <img
                                                src={icon1}
                                                alt="Icon depicting a journal or diary"
                                            />
                                        </div>
                                        <div className="icon-items__content">
                                            <p className="asap font-21">
                                                <strong>
                                                    Keep a journal or diary
                                                </strong>
                                            </p>
                                            <p>
                                                Tracking your symptoms and sleep
                                                patterns can help you and your
                                                healthcare provider better
                                                understand your narcolepsy.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="icon-items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon-items__icon">
                                            <img
                                                src={icon4}
                                                alt="Alarm clock icon"
                                            />
                                        </div>
                                        <div className="icon-items__content">
                                            <p className="asap font-21">
                                                <strong>
                                                    Maintain a regular
                                                    sleep-wake schedule
                                                </strong>
                                            </p>
                                            <p>
                                                Go to bed at the same time every
                                                night and wake up at the same
                                                time every morning.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="icon-items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon-items__icon">
                                            <img
                                                src={icon2}
                                                alt="Icon depicting a person napping"
                                            />
                                        </div>
                                        <div className="icon-items__content">
                                            <p className="asap font-21">
                                                <strong>
                                                    Take short naps during the
                                                    day
                                                </strong>
                                            </p>
                                            <p>
                                                Brief morning or afternoon naps
                                                (about 15 minutes) may help you
                                                feel less sleepy later in the
                                                day.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="icon-items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon-items__icon">
                                            <img
                                                src={icon5}
                                                alt="Icon of coffee cup with exclamation point"
                                            />
                                        </div>
                                        <div className="icon-items__content">
                                            <p className="asap font-21">
                                                <strong>
                                                    Avoid caffeine in the
                                                    evening
                                                </strong>
                                            </p>
                                            <p>
                                                Avoid coffee, tea, and other
                                                caffeinated beverages,
                                                especially in the evening.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="icon-items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon-items__icon">
                                            <img
                                                src={icon3}
                                                alt="Icon showing alcohol with a slash through it"
                                            />
                                        </div>
                                        <div className="icon-items__content">
                                            <p className="asap font-21">
                                                <strong>Limit alcohol</strong>
                                            </p>
                                            <p>
                                                Alcoholic drinks can interfere
                                                with normal sleep patterns.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="icon-items">
                                <ul className="icon-list">
                                    <li>
                                        <div className="icon-items__icon">
                                            <img
                                                src={icon6}
                                                alt="Icon of person practicing mindfulness"
                                            />
                                        </div>
                                        <div className="icon-items__content">
                                            <p className="asap font-21">
                                                <strong>Reduce stress</strong>
                                            </p>
                                            <p>
                                                Practice mindfulness by focusing
                                                on the present or joining a
                                                wellness program.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <div className="manage-day__content text-center">
                        <p className="asap asap-bold font-21">
                            Keep these tips handy. Save them to refer back to
                            later.
                        </p>
                        <div className="btn-wrap">
                            <a
                                href="/pdf/tips-for-living-with-narcolepsy.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary btn-primary--md dwn-btn"
                            >
                                Download tips
                                <svg
                                    id="download_arrow"
                                    data-name="download arrow"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="21"
                                    height="22"
                                    viewBox="0 0 21 22"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <path
                                                id="Clip_4"
                                                data-name="Clip 4"
                                                d="M0,0H2V16H0Z"
                                                fill="none"
                                            />
                                        </clipPath>
                                    </defs>
                                    <path
                                        id="Fill_1"
                                        data-name="Fill 1"
                                        d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                        transform="translate(0 17)"
                                        fill="#fff"
                                    />
                                    <g
                                        id="Group_5"
                                        data-name="Group 5"
                                        transform="translate(9)"
                                    >
                                        <path
                                            id="Clip_4-2"
                                            data-name="Clip 4"
                                            d="M0,0H2V16H0Z"
                                            fill="none"
                                        />
                                        <g
                                            id="Group_5-2"
                                            data-name="Group 5"
                                            clipPath="url(#clip-path)"
                                        >
                                            <path
                                                id="Fill_3"
                                                data-name="Fill 3"
                                                d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                fill="#fff"
                                            />
                                        </g>
                                    </g>
                                    <path
                                        id="Fill_6"
                                        data-name="Fill 6"
                                        d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                        transform="translate(3 9)"
                                        fill="#fff"
                                    />
                                </svg>
                            </a>
                        </div>
                        <p className="text-italic">
                            This information does not take the place of talking
                            with your healthcare provider. Always talk to your
                            healthcare provider about any symptoms you may be
                            feeling.
                        </p>
                    </div>
                </div>
            </section>

            <section className="two-col no-pb no-pt">
                <div className="col bg-img">
                    <div className="two-col__inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={resourcesImg}
                                className="two-col"
                                alt="Kristen, a real person living with narcolepsy, sitting with her laptop"
                            />
                        ) : (
                            <img
                                src={resourcesMobileImg}
                                className="two-col"
                                alt="Kristen, a real person living with narcolepsy, sitting with her laptop"
                            />
                        )}
                        <div className="two-col__content ml-76">
                            <div className="two-col__title text-white has-textShadow">
                                <h3>
                                    Access resources to start a conversation
                                    with your healthcare provider
                                </h3>
                            </div>
                            <div className="btn-wrap m-content">
                                <Link
                                    to="/resources-and-support/downloads"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    Go now
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col bg-lightBlue">
                    <div className="two-col__inner cta-flt-left blue-cta-spacer-lt">
                        <div className="two-col__desc">
                            <div className="two-col__image">
                                <img
                                    src={supportIcon}
                                    style={{
                                        maxWidth: "190px",
                                        width: "190px",
                                    }}
                                    alt="Connecting with others in the narcolepsy community"
                                />
                            </div>
                            <div className="two-col__title two-col__title--alt text-white">
                                <h3>
                                    Connect with others <br /> in the narcolepsy{" "}
                                    <br /> community
                                </h3>
                                <div className="btn-wrap">
                                    <Link
                                        to="/resources-and-support/support-organizations"
                                        className="btn btn-primary btn-primary--md"
                                    >
                                        Find support groups
                                        <TfiAngleRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialIconBlock />
        </HcpLayout>
    );
};

export default TipForLivingWithNarcolepsy;

export const Head = () => (
    <Seo
        title="Tips for Living With Narcolepsy | WAKIX® (pitolisant)"
        description="Find tips for living with narcolepsy. Hear other people living with narcolepsy share their tips for managing day to day."
        keywords="living with narcolepsy, living with EDS, living with excessive daytime sleepiness, narcolepsy tips, EDS tips, excessive daytime sleepiness tips, narcolepsy quality of life, EDS quality of life, tips for living with narcolepsy"
    />
);
