import React from "react";
import HcpLayout from "../../../../components/hcp/layout";
import { Seo } from "../../../../components/seo";
import { Row, Col } from "react-grid-system";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import resourceImg from "../../../../images/what-should-i-except/wakix-for-you-cta-desktop.webp";
import resourceMobileImg from "../../../../images/what-should-i-except/wakix-for-you-cta-mobile.webp";
import expectationsImg from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/expectations-desktop.webp";
import expectationsMobileImg from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/expectations-mobile.webp";
import TabAccordion from "../../../../components/tabAccordion";
import TabAccordionList from "../../../../components/tabAccordion/tabAccordionList";
import TabAccordionCustomTab from "../../../../components/tabAccordion/tabAccordionCustomTab";
import TabAccordionPanel from "../../../../components/tabAccordion/tabAccordionPanel";
import reducedSleepIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Clinical Trial Icons_Reduced Sleepiness.svg";
import homeworkIcon from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/homework-icon.svg";
import classroomIcon from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/classroom-icon.svg";
import tableDesktopImg from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/side-effects-table-desktop.svg";
import tableMobileImg from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/side-effects-table-mobile.svg";
import talkIcon from "../../../../images/WAKIX-clinical-trial-results/1.2 Side Effect Icons_Talk.svg";
import SocialIconBlock from "../../../../components/socialIconBlock";
import bannerImg from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/pediatric-clinical-trials-banner-img.webp";
import bannerImgMobile from "../../../../images/wakix-clinical-trials/pediatric-clinical-trial-results/pediatric-clinical-trials-banner-mob-img.webp";
import useDeviceSize from "../../../../hooks/use-device-size";
// import { StaticImage } from "gatsby-plugin-image";
import InnerBanner from "../../../../components/InnerBanner";
import "./pediatric-clinical-trial-results.scss";

const WakixPediatricClinicalTrialResults = () => {
  const deviceSizes = useDeviceSize();

  return (
    <HcpLayout pageClass="hcp-main wakix-clinical-trial-results wakix-pediatric-clinical-trial-results">
      <InnerBanner
        topHeading="EDS in Children 6-17 Years Old"
        bannerHeroImg={bannerImg}
        bannerHeroImgMobile={bannerImgMobile}
        bannerImgAlt="Parent or caregiver with child doing homework at table"
      />

      <section className="inner-first no-pb">
        <div className="clinical-trial__info">
          <div className="centered-content">
            <h1 className="font-40 mb-12">
              Results With WAKIX in the Clinical Trial of Children 6-17 Years
              Old
            </h1>
            <h2 className="asap font-25">
              <strong>
                Significantly reduced excessive daytime sleepiness (EDS)
              </strong>{" "}
              in patients 6-17 years old with narcolepsy compared with placebo
            </h2>
          </div>
        </div>
      </section>

      <section className="tab-block gray-bg no-pt">
        <div className="centered-content">
          <TabAccordion>
            <TabAccordionList>
              <TabAccordionCustomTab>EDS Results</TabAccordionCustomTab>
              <TabAccordionCustomTab>Side Effects</TabAccordionCustomTab>
            </TabAccordionList>
            <TabAccordionPanel id="tab1">
              <div className="clinicial-content">
                <h3 className="asap font-25 blue">
                  WAKIX significantly reduced excessive daytime sleepiness (EDS)
                  compared with placebo
                </h3>
                <p className="btm-spacer">
                  WAKIX was studied for the treatment of <strong>EDS</strong> in
                  a <strong>clinical trial</strong> of{" "}
                  <strong>
                    110 patients (aged 6-17 years) with narcolepsy
                  </strong>
                  .
                </p>
                <p className="btm-spacer">
                  WAKIX was compared with placebo (sugar pill) using the
                  Pediatric Daytime Sleepiness Scale (PDSS) questionnaire.
                </p>
                <p className="btm-spacer">
                  This questionnaire uses an overall score to measure excessive
                  daytime sleepiness by asking 8 questions about how often one
                  falls asleep or gets drowsy during the day in situations such
                  as:
                </p>
                <Row justify="center" className="row symptoms">
                  <Col lg={4}>
                    <div className="card-icon">
                      <img
                        src={homeworkIcon}
                        alt="Falling asleep while reading icon"
                      />
                      <p className="asap asap-bold font-21">Homework</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="card-icon">
                      <img
                        src={classroomIcon}
                        alt="Falling asleep in the classroom icon"
                      />
                      <p className="asap asap-bold font-21">Classroom</p>
                    </div>
                  </Col>
                </Row>
                <h4 className="font-21 asap">In this 8-week clinical trial:</h4>
                <ul className="bullet-list">
                  <li>
                    72 patients were treated with WAKIX, and 38 patients
                    received placebo.{" "}
                  </li>
                  <li>
                    90 of the patients in the trial had narcolepsy with
                    cataplexy, and 20 patients had narcolepsy without cataplexy.
                  </li>
                </ul>
                <h4 className="font-21 asap">At the end of the trial…</h4>
                <ul className="icon-list">
                  <li>
                    <div style={{ width: "21%" }} className="icon">
                      <img
                        src={reducedSleepIcon}
                        style={{ width: "100%" }}
                        alt="Reduced sleepiness icon"
                      />
                    </div>
                    <div className="text">
                      <h4 className="font-21 asap">
                        Scores on the PDSS were significantly improved for
                        patients taking WAKIX compared with placebo
                      </h4>
                      <p>
                        This means patients taking WAKIX reported falling asleep
                        or getting drowsy less often during the day compared
                        with patients who took placebo.
                      </p>
                    </div>
                  </li>
                  <li>
                    <p>
                      Everyone responds to medication differently. Individual
                      responses varied in the clinical trial.
                    </p>
                  </li>
                </ul>
              </div>
            </TabAccordionPanel>
            <TabAccordionPanel id="tab2">
              <div className="clinicial-content">
                <h3 className="asap font-25 blue">
                  WAKIX side effects in the clinical trial of children 6-17
                  years old
                </h3>
                <p className="btm-spacer">
                  In the 8-week clinical trial of 110 patients (aged 6-17 years)
                  with narcolepsy, the most common side effects of WAKIX were
                  headache and insomnia.
                </p>
                <div className="center-title text-center">
                  <h4 className="font-21 asap">
                    Side effects that occurred in at least 5% of patients
                    treated with WAKIX and more frequently than placebo
                  </h4>
                </div>
                <div className="media">
                  <img
                    src={tableDesktopImg}
                    alt="Table showing side effects of WAKIX in the clinical trial in children 6-17 years old"
                    className="desktop-view"
                  />
                  <img
                    src={tableMobileImg}
                    alt="Table showing side effects of WAKIX in clinical trials"
                    className="mobile-view"
                  />
                </div>
                <p className="font-14 btm-spacer">
                  <i>
                    n indicates the number of patients who were treated with WAKIX.
                  </i>
                </p>
                <p className="btm-spacer">
                  Overall, the side effects seen in the clinical trial of
                  patients aged 6-17 years with narcolepsy were similar to those
                  seen in adult clinical trials.
                </p>
                <p className="btm-spacer">
                  In the clinical trials of adults with narcolepsy:
                </p>
                <ul className="bullet-list">
                  <li>
                    The most common side effects of WAKIX were insomnia, nausea,
                    and anxiety.
                  </li>
                  <li>
                    Other side effects of WAKIX included headache, upper
                    respiratory tract infection, musculoskeletal pain, increased
                    heart rate, hallucinations, irritability, abdominal pain,
                    sleep disturbance, and decreased appetite.
                  </li>
                </ul>
                <ul className="icon-list">
                  <li style={{ marginBottom: "0" }}>
                    <div className="icon medium">
                      <img src={talkIcon} alt="Talk bubble icon" />
                    </div>
                    <div className="text">
                      <p>
                        <strong>
                          These are not all the possible side effects of WAKIX.{" "}
                        </strong>{" "}
                        Call your healthcare provider for medical advice about side effects.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </TabAccordionPanel>
          </TabAccordion>
        </div>
        <div className="btn-wrap btn-spacer">
          <Link
            to="/about-wakix/wakix-clinical-trials/adult-clinical-trial-results"
            className="btn btn-primary"
          >
            View <span className="lcase">results in adults</span>
            <TfiAngleRight />
          </Link>
        </div>
      </section>

      <section className="banner-block two-columns no-pt no-pb">
        <div className="col bg-img">
          <div className="inner bg-cover cta-img-hgt inner--alt">
            <img
              src={expectationsImg}
              className="two-col__desktopImg"
              alt="Parent or caregiver and child discussing treatment with healthcare provider"
            />
            <img
              src={expectationsMobileImg}
              className="two-col__mobileImg"
              alt="Parent or caregiver and child discussing treatment with healthcare provider"
            />
            <div className="content">
              <h3 className="asap">
                Getting started <br className="show-mobile" />
                with WAKIX
              </h3>
            </div>
            <div className="btn-wrap btn-wrap-align-left">
              <Link
                to="/starting-wakix/what-to-expect"
                className="btn btn-primary btn-primary--md"
              >
                Find out
                <TfiAngleRight />
              </Link>
            </div>
          </div>
        </div>
        <div className="col bg-img">
          <div className="inner bg-cover cta-img-hgt">
            {deviceSizes?.smUp ? (
              <img
                src={resourceImg}
                className="two-col img-flip"
                alt="WAKIX for You Patient Case Manager with headset"
              />
            ) : (
              <img
                src={resourceMobileImg}
                className="two-col"
                alt="WAKIX for You Patient Case Manager with headset"
              />
            )}
            <div className="content cout-left-spacer">
              <h3 className="asap green asap-bold">
                The <span className="italic asap asap-bold">WAKIX for You</span>{" "}
                patient support program is <br className="show-mobile" />
                here to help
              </h3>
            </div>
            <div className="btn-wrap cout-left-spacer">
              <Link
                to="/wakix-for-you-support-program"
                className="btn btn-primary btn-primary--md"
              >
                View available support
                <TfiAngleRight />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <SocialIconBlock />
    </HcpLayout>
  );
};

export default WakixPediatricClinicalTrialResults;

export const Head = () => (
  <Seo
    title="Pediatric Clinical Trial Results | WAKIX® (pitolisant)"
    description="WAKIX was studied for the treatment of excessive daytime sleepiness (EDS) in patients aged 6-17 years with narcolepsy. Learn about the results."
    keywords="WAKIX pediatric results, WAKIX ages 6 years and older, pitolisant pediatric results, WAKIX children results, pitolisant children results, WAKIX pediatric safety, pitolisant pediatric safety, WAKIX pediatric before after, pitolisant pediatric before after"
  />
);
